<template>
  <div class="internManage">
    <div class="title">
      <div class="titleFlex">
        <div class="titleTop">实习生管理</div>
        <el-radio-group
          v-model="activeName"
          @input="changeSaveTabs"
          size="mini"
        >
          <el-radio-button label="forRecruitment">待招聘</el-radio-button>
          <el-radio-button label="onTheJob">已入职</el-radio-button>
          <el-radio-button label="haveLeft">已离职</el-radio-button>
          <el-radio-button label="refusedEntry">拒绝入职</el-radio-button>
        </el-radio-group>
        <div class="bottomButton">
          <el-button @click="reset" class="resetButton">
            <i class="el-icon-refresh-left buttonrefreshSpan"></i>
          </el-button>
          <el-button @click="exportAllInternListClick"
                     role="exportAllInternListClick"
                     v-permission
          >下载全部Intern List</el-button>
          <el-button type="primary" @click="search">搜索</el-button>
        </div>
      </div>
      <searchFormLine
        @onSearch="onSearch"
        :position="activeName"
        ref="searchFormLineRef"
      />
    </div>
    <div class="internManage__content">
      <component ref="componentRef" :is="activeName" />
    </div>
  </div>
</template>

<script>
import forRecruitment from './components/forRecruitment.vue'
import onTheJob from '@/views/intern/components/onTheJob'
import stayPositive from '@/views/intern/components/stayPositive'
import haveLeft from '@/views/intern/components/haveLeft'
import refusedEntry from '@/views/intern/components/refusedEntry'
import { getItem, removeItem, setItem } from '@/utils/storage'
import SearchFormLine from '@/views/intern/components/searchFormLine'
import { exportInternList } from "@/api/intern";
export default {
  name: 'internManage',
  components: {
    forRecruitment,
    onTheJob,
    stayPositive,
    haveLeft,
    refusedEntry,
    SearchFormLine
  },
  data() {
    return {
      activeName: ''
    }
  },
  created() {
    let internTab = getItem('internTab')
    if (internTab) {
      this.activeName = internTab
    } else {
      this.activeName = 'forRecruitment'
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log(to.path)
    if (!to.path.includes('intern')) {
      removeItem('internTab')
    }
    next()
  },
  methods: {
    reset() {
      this.$refs.searchFormLineRef.reset()
      this.$refs.searchFormLineRef.search()
    },
    search() {
      this.$refs.searchFormLineRef.search()
    },
    queryApi(val = {}) {},
    handleSelectionChange(val) {
      this.selectVal = val
    },
    onSearch(val) {
      setItem('internSearchData', val)
      this.$refs.componentRef.pageConfig.pageNum = 1
      this.$refs.componentRef.queryApi(val)
    },
    changeSaveTabs() {
      this.reset()
      setItem('internTab', this.activeName)
    },
    exportAllInternListClick() {
      // let ruleForm = this.searchVal
      let data = {
        // englishName: '',
        // status: '',
        // localName: '',
        // companyName: '',
        // lmName: '',
        // // ids: this.mapSelectValToIds(),
        // title: '',
        // taOwner: '',
        // internalBrand: '',
        // joinDate: [],
        // leaveDate: [],
        tabType: '6',
        // tbhStatus: this.ruleForm.tbhStatus,
        // ...ruleForm
      }
      exportInternList(data)
    },
  }
}
</script>

<style lang="scss" scoped>
.internManage {
  width: 100%;
  //height: calc(100vh - 60px - 20px);
  .title {
    padding: 20px;
    .titleFlex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    background: #ffffff;
    border-radius: 4px;
    .titleTop {
      font-size: 21px;
      font-weight: 500;
      color: #303133;
      //padding-top: 11px;
    }
  }
  &__content {
    margin-top: 15px;
    background: #ffffff;
    padding: 20px 10px 20px 20px;
    //height: calc(100vh - 60px - 144px - 10px - 20px - 15px - 45px);
  }
}
</style>
