<template>
  <div class="forRecruitment">
    <el-row style="margin-top: -15px; margin-bottom: 10px">
      <el-radio-group
        v-model="ruleForm.tbhStatus"
        @change="changeTbhStatus"
        style="margin-top: 10px"
      >
        <el-radio label="0">ALL</el-radio>
        <el-radio label="2">待办</el-radio>
        <el-radio label="3">已办</el-radio>
      </el-radio-group>
    </el-row>
    <el-row>
      <el-button size="mini" role="create" v-permission @click="NewIntern"
        >新建实习生</el-button
      >
      <el-button
        role="sendInductionMail"
        v-permission
        size="mini"
        @click="sendInductionEmailClick"
        >发送入职通知邮件</el-button
      >
      <el-button size="mini" @click="preparationForEntry"
        >再次通知OPS</el-button
      >
      <el-button
        size="mini"
        role="sendItMail"
        v-permission
        @click="sendITAdminEmailClick"
        >再次通知IT/Admin</el-button
      >
      <el-button
        size="mini"
        role="exportInternList"
        v-permission
        @click="exportInternListClick"
        >导出Intern List</el-button
      >
      <el-button
        size="mini"
        role="downloadInternAttachment"
        v-permission
        @click="downloadAttachClick"
        >打包下载实习生附件</el-button
      >
      <el-button size="mini" role="back" v-permission @click="takeBackClick"
        >回收</el-button
      >
      <el-button
        size="mini"
        role="distribution"
        v-permission
        @click="saveSelectUserClick"
        >分配</el-button
      >
      <el-button size="mini" role="ops" v-permission @click="intoOpsClick"
        >转入Ops</el-button
      >
      <el-button size="mini" role="sendTa" v-permission @click="sendTA"
        >发送TA</el-button
      >
    </el-row>

    <el-row style="margin-top: 12px">
      <PocTable
        v-loading="loading"
        :data="tableData"
        :max-height="10000000000000"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @selection-change="handleSelectionChange"
        @page-current-change="pageChange"
        stripe
      >
        <el-table-column type="selection" width="45"> </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column key="hfmCode" label="HFM Code" width="150">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="点击查看详情"
              placement="top-start"
              :open-delay="800"
            >
              <div class="hfmCodeHover" @click="clickHfmCode(scope.row)">
                {{ scope.row.hfmCode }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig1"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="center"
        >
        </el-table-column>

        <el-table-column
          v-for="item in columnConfigOps"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="center"
        >
          <template v-slot="scope">
            <div v-if="['inOps', 'sendTa'].includes(item.key)">
              <el-tooltip
                v-if="scope.row[item.key] === '是'"
                popper-class="workorder-reason-popper"
                effect="dark"
                :content="
                  item.key === 'sendTa'
                    ? scope.row.taOwner
                    : scope.row.inOpsUserNames
                "
                placement="top"
              >
                <span>{{ scope.row[item.key] }}</span>
              </el-tooltip>
              <span v-else></span>
            </div>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
          width="false"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <statusDisplay :statusContent="scope.row.status" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="145" fixed="right" align="center">
          <template slot-scope="scope">
            <RoleButton
              :key="Math.random()"
              :role-status="scope.row.status"
              @onClickButton="onClickButton($event, scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
    </el-row>
    <PersonnelSelectionDialog
      ref="PersonnelSelectionDialogRef"
      @onConfirm="saveSelectUserClickSave"
    />
    <NewInternDialog ref="NewInternDialogRef" @onClose="onClose" />
  </div>
</template>

<script>
import searchFormLine from '@/views/intern/components/searchFormLine'
import RoleButton from '@/views/intern/components/RoleButton'
import NewInternDialog from '@/views/intern/dialog/NewInternDialog'
import {
  forRecruitmentColumn,
  forRecruitmentColumn1
} from '@/views/intern/columnConfig/forRecruitmentColumn'
import statusDisplay from '@/views/intern/components/statusDisplay'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import {
  deleteItems,
  downloadAttach,
  downloadInternshipCertificate,
  entryPreparation,
  exportInternList,
  internList,
  internshipAgreement,
  intoOps,
  recall,
  rejectOffer,
  saveSelectUser,
  sendInductionEmail,
  sendITAdminEmail,
  deleteInRecruiting,
  takeBack
} from '@/api/intern'
import { getItem } from '@/utils/storage'

export default {
  name: 'forRecruitment',
  mixins: [$PCommon.TableMixin],
  components: {
    searchFormLine,
    statusDisplay,
    RoleButton,
    PersonnelSelectionDialog,
    NewInternDialog
  },
  data() {
    return {
      ruleForm: {
        tbhStatus: '0'
      },
      columnConfig: forRecruitmentColumn,
      columnConfig1: forRecruitmentColumn1,
      columnConfigOps: [
        {
          key: 'sendTa',
          name: '已经发送TA',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'inOps',
          name: '已转入OPS',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        }
      ],
      tableData: [],
      loading: false,
      selectVal: [],
      dialogStatus: '',
      maxHeight: '',
      searchVal: {}
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 445
    this.queryApi()
  },
  methods: {
    NewIntern() {
      // this.$router.push({
      //   path: '/intern/newIntern'
      // })
      this.$refs.NewInternDialogRef.show()
    },
    preparationForEntry() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        entryPreparation(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('发送OPS通知邮件成功')
          }
        })
      }
    },
    edit(val) {
      this.$refs.NewInternDialogRef.show({
        id: val.id,
        status: 'edit'
      })
      // this.$router.push({
      //   path: '/intern/newIntern',
      //   query: {
      //     id: val.id,
      //     status: 'edit'
      //   }
      // })
    },
    change(val) {
      this.$router.push({
        path: '/intern/editIntern',
        query: {
          id: val.id,
          status: 'edit'
        }
      })
    },
    onClose() {
      this.queryApi()
    },
    changeTbhStatus() {
      this.queryApi()
    },
    clickHfmCode(item) {
      console.log(item)
      this.$router.push({
        path: '/intern/internDetails',
        query: {
          id: item.id
        }
      })
    },
    queryApi(val = {}) {
      this.searchVal = val
      this.loading = true
      const data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '1',
        tbhStatus: this.ruleForm.tbhStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        ...val,
        ...getItem('internSearchData')
      }
      internList(data).then((res) => {
        if (res && res.code === 200) {
          setTimeout(() => {
            this.loading = false
          }, 1000)

          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    mapSelectValToIds() {
      return this.selectVal.map((item) => item.id).toString(',')
    },
    sendInductionEmailClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        sendInductionEmail(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('发送入职通知邮件成功')
          }
        })
      }
    },
    downloadAttachClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        downloadAttach(data)
      }
    },
    sendITAdminEmailClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        sendITAdminEmail(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('发送IT/Admin通知邮件成功')
          }
        })
      }
    },
    exportInternListClick() {
      let ruleForm = this.searchVal
      let data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        ids: this.mapSelectValToIds(),
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '1',
        tbhStatus: this.ruleForm.tbhStatus,

        ...ruleForm
      }
      exportInternList(data)
    },
    sendTA() {
      if (this.isSelect()) {
        this.dialogStatus = 'sendTA'
        this.$refs.PersonnelSelectionDialogRef.show({
          position: 'sendTA'
        })
      }
    },
    takeBackClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        takeBack(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('回收成功')
          }
        })
      }
    },
    saveSelectUserClick() {
      this.dialogStatus = 'ops'
      this.$refs.PersonnelSelectionDialogRef.show({
        position: 'sendTA'
      })
    },
    saveSelectUserClickSave(val) {
      if (this.dialogStatus === 'ops') {
        const data = {
          userId: val.map((item) => item.id).toString(','),
          type: 'distribution',
          id: this.mapSelectValToIds()
        }
        saveSelectUser(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('分配成功')
            this.queryApi()
          }
        })
      } else if (this.dialogStatus === 'sendTA') {
        const data = {
          userId: val.map((item) => item.id).toString(','),
          type: 'ta',
          id: this.mapSelectValToIds()
        }
        saveSelectUser(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('发送TA成功')
            this.queryApi()
          }
        })
      }
    },
    intoOpsClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        intoOps(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('转入Ops成功')
            this.queryApi()
          }
        })
      }
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    copy(row) {
      this.$refs.NewInternDialogRef.show({
        id: row.id,
        hfmCode: row.hfmCode,
        status: 'copy'
      })
      // this.$router.push({
      //   path: '/intern/newIntern',
      //   query: {
      //     id: row.id,
      //     status: 'copy'
      //   }
      // })
    },
    deleteItem(val) {
      const data = {
        ids: val.id
      }
      deleteItems(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('删除成功')
          this.queryApi()
        }
      })
    },
    pdf(row) {
      internshipAgreement({ id: row.id }).then((res) => {
        if (res && res.code === 200) {
          window.open(res.data)
        }
      })
    },
    reject(row) {
      rejectOffer({ ids: row.id }).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('拒绝成功')
          this.queryApi()
        }
      })
    },
    /**
     * 删除 待招聘 实习生
     */
    deleteInRecruiting(row) {
      this.$confirm(
        '确定删除实习生【' + row.localName + '】? 该操作不可撤回',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          deleteInRecruiting({ id: row.id }).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('删除成功')
              this.queryApi()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /**
     * 下载实习证明
     */
    downloadInternshipCertificate(row) {
      downloadInternshipCertificate({
        internId: row.id
      })
    },
    onClickButton(event, row) {
      const buttonObj = {
        change: () => {
          this.edit(row)
        },
        edit: () => {
          this.change(row)
        },
        copy: () => {
          this.copy(row)
        },
        deleteItem: () => {
          this.deleteItem(row)
        },
        pdf: () => {
          this.pdf(row)
        },
        reject: () => {
          this.reject(row)
        },
        deleteInRecruiting: () => {
          this.deleteInRecruiting(row)
        },
        downloadInternshipCertificate: () => {
          this.downloadInternshipCertificate(row)
        }
      }
      buttonObj[event]()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.forRecruitment {
  .hfmCodeHover {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: dodgerblue;
    }
  }
}
</style>
<style lang="scss">
.hfmCodeHover {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: dodgerblue;
  }
}
</style>
