<template>
  <div class="stayPositive">
    <searchFormLine />
    <el-row>
      <el-button icon="el-icon-plus" type="primary">发送考勤邮件</el-button>
      <el-button>导出Intern List</el-button>
    </el-row>
    <el-row style="margin-top: 10px">
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @selection-change="handleSelectionChange"
        @page-current-change="pageChange"
        stripe
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>

        <el-table-column
          label="Status"
          width="100"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope"> </template>
        </el-table-column>

        <el-table-column label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope"> </template>
        </el-table-column>
      </PocTable>
    </el-row>
  </div>
</template>

<script>
import searchFormLine from '@/views/intern/components/searchFormLine'
import { stayPositiveColumn } from '@/views/intern/columnConfig/stayPositiveColumn'
import { internList } from '@/api/intern'
export default {
  name: 'stayPositive',
  mixins: [$PCommon.TableMixin],
  components: {
    searchFormLine
  },
  data() {
    return {
      columnConfig: stayPositiveColumn,
      tableData: [],
      loading: false,
      selectVal: []
    }
  },
  created() {
    this.queryApi()
  },
  methods: {
    onSearch(val) {
      this.queryApi(val)
    },
    queryApi(val = {}) {
      const data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '3',
        tbhStatus: this.tbhStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        ...val
      }
      internList(data).then((res) => {
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.stayPositive {
}
</style>
