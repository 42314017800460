<template>
  <div class="onTheJob">
    <el-row>
      <el-button @click="exportInternListClick">导出Intern List</el-button>
      <el-button @click="downloadAttachClick">打包下载实习生附件</el-button>
      <el-button @click="batchDownloadInternshipCertificate">打包下载实习证明</el-button>
      <!--      <el-button>实习生转正</el-button>-->
    </el-row>
    <el-row style="margin-top: 12px">
      <PocTable
        v-loading="loading"
        :data="tableData"
        ref="tableRef"
        :max-height="10000000000000"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @selection-change="handleSelectionChange"
        @page-current-change="pageChange"
        stripe
      >
        <el-table-column type="selection" width="43"> </el-table-column>
        <el-table-column
          v-for="item in columnConfig1"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column key="hfmCode" label="HFM Code" width="150">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="点击查看详情"
              placement="top-start"
              :open-delay="800"
            >
              <div class="hfmCodeHover" @click="clickHfmCode(scope.row)">
                {{ scope.row.hfmCode }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig2"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column
          v-for="item in columnConfig"
          v-if="item.visible"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        ></el-table-column>

        <el-table-column
          label="Status"
          width="100"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <statusDisplay :statusContent="scope.row.status" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="175" fixed="right" align="center">
          <template v-slot="scope">
            <RoleButton
              :key="Math.random()"
              :roleStatus="scope.row.status"
              @onClickButton="onClickButton($event, scope.row)"
            />
          </template>
        </el-table-column>
      </PocTable>
      <el-dialog
        title="发送考勤邮件"
        :visible.sync="sendAttendanceEmail"
        width="30%"
        :before-close="sendAttendanceEmailClose">
        <div style="height:70px">
          <span>选择考勤月份：&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <el-date-picker v-model="attdanceMonth" type="month" placeholder="选择月">
          </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="sendAttendanceEmail = false">取 消</el-button>
            <el-button type="primary" @click="sendAttendanceEmailsByMonth()">确 定</el-button>
          </span>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import searchFormLine from '@/views/intern/components/searchFormLine'
import { onTheJobColumn } from '@/views/intern/columnConfig/onTheJobColumn'
import {
  forRecruitmentColumn,
  forRecruitmentColumn1
} from '@/views/intern/columnConfig/forRecruitmentColumn'
import {
  cancelLeave,
  confirmLeave,
  downloadAttach,
  batchDownloadInternshipCertificate,
  downloadInternshipCertificate,
  exportInternList,
  internList,
  internshipAgreement,
  internshipCertificate,
  sendAttendance,
  sendLeave
} from '@/api/intern'
import statusDisplay from '@/views/intern/components/statusDisplay'
import RoleButton from '@/views/intern/components/RoleButton'
import { getItem } from '@/utils/storage'
export default {
  name: 'onTheJob',
  mixins: [$PCommon.TableMixin],
  components: {
    searchFormLine,
    statusDisplay,
    RoleButton
  },
  data() {
    return {
      columnConfig: onTheJobColumn,
      tableData: [],
      loading: false,
      selectVal: [],
      maxHeight: '',
      columnConfig1: forRecruitmentColumn,
      columnConfig2: forRecruitmentColumn1,
      searchVal: {},
      sendAttendanceEmail: false,
      attdanceMonth: '',
      rowId: 0
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 400
    this.queryApi()
  },
  methods: {
    clickHfmCode(item) {
      console.log(item)
      this.$router.push({
        path: '/intern/internDetails',
        query: {
          id: item.id
        }
      })
    },
    exportInternListClick() {
      let ruleForm = this.searchVal
      let data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        ids: this.mapSelectValToIds(),
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '2',
        ...ruleForm
      }
      exportInternList(data)
    },
    mapSelectValToIds() {
      return this.selectVal.map((item) => item.id).toString(',')
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    downloadAttachClick() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        downloadAttach(data)
      }
    },
    /**
     * 打包下载实习证明
     */
     batchDownloadInternshipCertificate() {
      if (this.isSelect()) {
        const data = {
          ids: this.mapSelectValToIds()
        }
        batchDownloadInternshipCertificate(data).then((res) => {
          // res 下载的接口 无res情况下代表可以下载成功 有则代表报错
          if (!res) {
            batchDownloadInternshipCertificate(data, true)
          }
        })
      }
     },
    /**
     * 下载实习证明
     */
    downloadInternshipCertificate(row) {
      downloadInternshipCertificate({
        internId: row.id
      })
    },

    onClickButton(event, row) {
      const buttonObj = {
        sendAttendanceEmails: () => {
          this.sendAttendanceEmail = true
          this.rowId = row.id;
        },
        sendAnExitEmail: () => {
          this.sendAnExitEmail(row)
        },
        pdf: () => {
          this.pdf(row)
        },
        edit: () => {
          this.edit(row)
        },
        CancelTheDeparture: () => {
          this.CancelTheDeparture(row)
        },
        ConfirmTheDeparture: () => {
          this.ConfirmTheDeparture(row)
        },
        PracticeProvesThat: () => {
          this.PracticeProvesThat(row)
        },
        downloadInternshipCertificate: () => {
          this.downloadInternshipCertificate(row)
        }
      }
      buttonObj[event]()
    },
    PracticeProvesThat(val) {
      internshipCertificate({ id: val.id }).then((res) => {
        if (res && res.code === 200) {
          window.open(res.data)
        }
      })
    },
    edit(val) {
      this.$router.push({
        path: '/intern/editIntern',
        query: {
          id: val.id,
          status: 'edit'
        }
      })
    },
    ConfirmTheDeparture(row) {
      const h = this.$createElement
      this.$confirm('确认离职', {
        title:'确认离职',
        message: h('div', [h('p', '确认离职后，实习生状态不可恢复，'), h('p', '是否确认已离职？')]),
        cancelButtonText: '否',
        confirmButtonText:'是',
        type: 'warning'
      }).then(() => {
        const data = {
          ids: row.id
        }
        confirmLeave(data).then((res) => {
          if (res && res.code === 200) {
            this.queryApi()
            this.$message.success('确认离职成功')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    pdf(row) {
      internshipAgreement({ id: row.id }).then((res) => {
        if (res && res.code === 200) {
          window.open(res.data)
        }
      })
    },
    CancelTheDeparture(row) {
      const data = {
        ids: row.id
      }
      cancelLeave(data).then((res) => {
        if (res && res.code === 200) {
          this.queryApi()
          this.$message.success('撤销离职成功')
        }
      })
    },
    sendAttendanceEmailsByMonth() {
      const data = {
        id: this.rowId,
        attdanceMonth: this.attdanceMonth
      }
      sendAttendance(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('发送考勤邮件成功')
        }
      })
      this.sendAttendanceEmail = false
    },
    sendAttendanceEmailClose(done) {
      done();
    },
    sendAnExitEmail(row) {
      const data = {
        ids: row.id
      }
      sendLeave(data).then((res) => {
        if (res && res.code === 200) {
          this.queryApi()
          this.$message.success('发送离职邮件成功')
        }
      })
    },
    queryApi(val = {}) {
      if (val) {
        this.searchVal = val
      }
      this.loading = true
      const data = {
        englishName: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        tabType: '2',
        tbhStatus: this.tbhStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        ...val,
        ...getItem('internSearchData')
      }
      internList(data).then((res) => {
        if (res && res.code === 200) {
          this.loading = false
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
            setTimeout(()=>{
              if(this.$refs.tableRef){
                this.$refs.tableRef.doLayout()
              }
            },3000)
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.onTheJob {
}
</style>
